<template>
  <div class="home">
    <div class="bg--main">
      <div class="container container--header">
        <div class="header">
          <router-link to="/" class="header__logo" />
          <div class="header-block">
            <p class="header-block__name">
              Добрый день!
            </p>
            <img src="@/assets/icons/name_icon.png"
              srcset="@/assets/icons/name_icon@2x.png 2x" alt="" class="header-block__icon"
            >
          </div>
        </div>
      </div>
      <div class="container">
        <div class="meditation">
          <h1 class="meditation__tit">
            Готовы начать медитацию?
          </h1>
          <div style="padding:56.25% 0 0 0;position:relative;"><iframe :src="'https://player.vimeo.com/video/' + video + '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Функциональная 4 2.mp4"></iframe></div>
          <div class="meditation__girl"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      video: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const response = await this.$request.get('/api/guide/video', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.data.success) {
        this.$router.replace('/login');
      } else {
        this.video = response.data.result.videos[0].url;
      }
    },
  },
};
</script>
<style lang="scss">
  iframe {
    width: 140rem;
    margin: 0 auto 8rem;
    height: 60rem;
  }
  @media (max-width: 768px) {
    iframe {
      width: 34rem;
      height: 18rem;
    }
  }
</style>
